import { Type, Transform } from 'class-transformer';
import { Usuario } from "./usuario.model";
import { Actividad } from "./actividad.model";
import { environment } from 'environments/environment';

export interface IGastoGestion {
    id: number,
    created: Date,
    modified: Date,
    actividad_id: number,
    usuario_id: number,
    kilometros: number,
    importe: number,
}

export class GastoGestion implements IGastoGestion {
    public id: number;
    @Transform(({ value }) => new Date(value))
    public created: Date;
    @Transform(({ value }) => new Date(value))
    public modified: Date;
    public actividad_id: number;
    public usuario_id: number;
    public kilometros: number;
    public importe: number;
    @Type(() => Usuario)
    public usuario: Usuario;
    @Type(() => Actividad)
    public actividad: Actividad;


    getImporteKilometros() {
        return this.kilometros * environment.precio_kilometro;
    }
    getPrecioKilometro() {
        return environment.precio_kilometro;
    }
    getImporteTotal() {
        return this.importe + this.getImporteKilometros();
    }
}
