import { Transform } from 'class-transformer';

export interface INotificacion {
    id: number;
    title: string;
    body: string;
    template: string;
    extra_info: string;
    created: Date;
    modified: Date;
    read: boolean;
    state: number;
    tracking_id: string;
    unread: boolean;
    user_id: number;
    vars: JSON;
}

export class Notificacion implements INotificacion {
    public id: number;
    public title: string;
    public body: string;
    public template: string;
    public extra_info: string;
    public created: Date;
    public modified: Date;
    public read: boolean;
    public state: number;
    public tracking_id: string;
    public unread: boolean;
    public user_id: number;
    @Transform(({ value }) => {
        if (typeof value === 'string') {
            try {
                return JSON.parse(value);
            } catch (e) {
                console.error('Error parsing vars JSON:', e);
                return {};
            }
        }
        return value;
    })
    public vars: JSON;

    static get modelName() {
        return 'Notificacion'
    }

    getIcon() {
        return 'bell';
    }
    getUrl() {
        if (this.template === 'nuevo_mensaje') {
            return '/usuarios/muro?tab=mensajes';
        }
        return null;
    }
    getNotificatedModelName() {
        if (this.vars && typeof this.vars === 'object' && this.vars.hasOwnProperty('model')) {
            return this.vars['model'];
        }
        return null;
    }
    getExtraInfo() {
        if (this.vars && typeof this.vars === 'object' && this.vars.hasOwnProperty('extra_info')) {
            return this.vars['extra_info'];
        }
        return null;
    }

}
