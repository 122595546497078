import { Type } from 'class-transformer';
import { Propiedad } from "./propiedad.model";
import { Relacion } from './relacion.model';
import { Actividad } from './actividad.model';
import { TelefonoPipe } from 'app/pipes/telefono.pipe';
import { Archivo } from './archivo-model';
import { Oportunidad } from './oportunidad.model';


export interface IComunicacionContacto {
    id: number,
    tipo_comunicacion: string,
    tipo_participacion: string,
    created: Date,
    modified: Date,
    origen: string,
    destino: string,
    mensaje: string,
    subject: string,
    fecha_envio: Date,
    contacto_id: number,
    contacto_nombre: string,
    deleted: boolean,
}

export interface IEmailContacto {
    id: number,
    contacto_id: number,
    email: string,
    etiqueta: string,
}

export interface ITelefonoContacto {
    id: number,
    contacto_id: number,
    telefono: string,
    prefijo: string,
    etiqueta: string,
}

export class TelefonoContacto implements ITelefonoContacto {
    private telefonoFormateadoPipe = new TelefonoPipe();


    public id: number;
    public contacto_id: number;
    public telefono: string;
    public prefijo: string;
    public etiqueta: string;

    getNumeroCompleto() {
        if (this.prefijo) {
            //return '+'+this.prefijo +' '+this.telefonoFormateadoPipe.transform(this.telefono);
            return '+'+this.prefijo +' '+ this.telefono;
        }
        //return this.telefonoFormateadoPipe.transform(this.telefono);
        return this.telefono;
    }
}


export interface IContacto {
    id: number,
    nombre: string,
    identificador_fiscal: string,
    notas: string,
    direccion: string,
    localidad: string,
    pais: string,
    es_inversor: boolean,
    emails_contacto: IEmailContacto[],
    telefonos_contacto: TelefonoContacto[],
    comunicaciones: IComunicacionContacto[],
    propiedades: Propiedad[],
    relaciones: Relacion[],
    propietario_de: Relacion[],
    interesado_en: Relacion[],
    vinculado_a: Relacion[],
    actividades: Actividad[],
    documento_identificacion: Archivo[],
    oportunidades: Oportunidad[],
    deleted: Date,
}

export class Contacto implements IContacto {
    public id: number;
    public nombre: string;
    public identificador_fiscal: string;
    public notas: string;
    public direccion: string;
    public localidad: string;
    public pais: string;
    public es_inversor: boolean;
    public emails_contacto: IEmailContacto[];
    public deleted: Date;
    public comunicaciones: IComunicacionContacto[];
    @Type(() => TelefonoContacto)
    public telefonos_contacto: TelefonoContacto[];
    @Type(() => Propiedad)
    public propiedades: Propiedad[];
    @Type(() => Relacion)
    public relaciones: Relacion[];
    @Type(() => Relacion)
    public propietario_de: Relacion[];
    @Type(() => Relacion)
    public interesado_en: Relacion[];
    @Type(() => Relacion)
    public vinculado_a: Relacion[];
    @Type(() => Actividad)
    public actividades: Actividad[];
    @Type(() => Archivo)
    public documento_identificacion: Archivo[];
    @Type(() => Oportunidad)
    public oportunidades: Oportunidad[];

    static get modelName() {
        return 'Contacto'
    }

    getPropiedades() {
        if (this.propietario_de && this.propietario_de.constructor === Array && this.propietario_de.length > 0) {
            const propiedades:Propiedad[] = [];
            this.propietario_de.forEach(rel => {
                propiedades.push(rel.propiedad);
            });
            return propiedades;
        }
        if (this.relaciones && this.relaciones.constructor === Array) {
            const relaciones = this.relaciones.filter(elem => elem.tipo_relacion_id == 1); //2 = otro 3 = interesado 1 = propietario
            const propiedades:Propiedad[] = [];
            relaciones.forEach(rel => {
                propiedades.push(rel.propiedad);
            });
            return propiedades;
        }
        return [];
    }
    esPropietario() {
        return this.getPropiedades().length > 0;
    }
    getOportunidadesActivas() {
        if (this.oportunidades && this.oportunidades.constructor === Array && this.oportunidades.length > 0) {
            return this.oportunidades.filter(elem => elem.closed == null);
        }
        return [];
    }
    getIntereses() {
        if (this.interesado_en && this.interesado_en.constructor === Array && this.interesado_en.length > 0) {
            return this.interesado_en.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));
        }
        if (this.relaciones && this.relaciones.constructor === Array) {
            const relaciones = this.relaciones.filter(elem => elem.tipo_relacion_id == 3); //2 = otro 3 = interesado 1 = propietario
            const propiedades:Propiedad[] = [];
            relaciones.forEach(rel => {
                propiedades.push(rel.propiedad);
            });
            return propiedades;
        }
        return [];
    }
    getInteresesActivos() {
        if (this.interesado_en && this.interesado_en.constructor === Array && this.interesado_en.length > 0) {
            const intereses = this.interesado_en.filter(elem => elem.fecha_hasta == null);
            return intereses.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));
        }
        return [];
    }
    getInteresesCaducados() {
        if (this.interesado_en && this.interesado_en.constructor === Array && this.interesado_en.length > 0) {
            const intereses = this.interesado_en.filter(elem => elem.fecha_hasta && (new Date(elem.fecha_hasta) <= new Date()));
            return intereses.sort((a, b) => (a.fecha_hasta < b.fecha_hasta) ? 1 : ((b.fecha_hasta < a.fecha_hasta) ? -1 : 0));
        }
        return [];
    }
    getInteresesEnOportunidad() {
        if (this.interesado_en && this.interesado_en.constructor === Array && this.interesado_en.length > 0) {
            const intereses = this.interesado_en.filter(elem => elem.oportunidad_id != null);
            return intereses.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));
        }
        return [];
    }

    getPropiedadesInteresado() {
        if (this.interesado_en && this.interesado_en.constructor === Array && this.interesado_en.length > 0) {
            const propiedades:Propiedad[] = [];
            this.interesado_en.forEach(rel => {
                propiedades.push(rel.propiedad);
            });
            return propiedades;
        }
        if (this.relaciones && this.relaciones.constructor === Array) {
            const relaciones = this.relaciones.filter(elem => elem.tipo_relacion_id == 3); //2 = otro 3 = interesado 1 = propietario
            const propiedades:Propiedad[] = [];
            relaciones.forEach(rel => {
                propiedades.push(rel.propiedad);
            });
            return propiedades;
        }
        return [];
    }
    esComprador() {
        return this.getIntereses().length > 0;
    }
    esVendedor() {
        if (this.propietario_de && this.propietario_de.constructor === Array && this.propietario_de.length > 0) {
            const propiedades:Propiedad[] = [];
            this.propietario_de.forEach(rel => {
                if (rel.propiedad.estado == 'disponible') {
                    propiedades.push(rel.propiedad);
                }
            });
            return propiedades.length > 0;
        }
        return false;
    }

    getProximasCitas() {
        //Devolvemos las actividades con tipo reunion_venta que aun no han pasado
        if (this.actividades && this.actividades.constructor === Array) {
            const currentDate = new Date();
            const actividades = this.actividades.filter(elem => {
                if (elem.tipo === 'reunion_venta') {
                  const fechaActividad = new Date(elem.fecha);
                  return fechaActividad > currentDate;
                }
                return false;
              });
            return actividades;
        }
        return [];
    }

    getTareasActivas() {
        //Devolvemos las actividades con tipo tarea que no se han completado aun
        if (this.actividades && this.actividades.constructor === Array) {
            const actividades = this.actividades.filter(elem => elem.tipo == 'tarea' && !elem.completada);
            return actividades;
        }
        return [];
    }


    getProblemsVenta(all = false) {

        let problems = [
            {
                key: 'id',
                nombre: 'Documento de identificación',
                msg: 'No hay documento de identificación',
                ayuda: 'Es necesario subir documento de identificación',
                status: false,
            },
        ];
        if (!this.documento_identificacion) {
            problems = problems.map(p =>   p.key === 'id' ? { ...p, status: true } : p );
        }

        if (!all) {
            let problems_return = problems.filter(p => p.status);
            return problems_return;
        }
        return problems;
    }
    hasProblemVenta(param) {
        let problems = this.getProblemsVenta(true);
        let problem = problems.find(p => p.key == param);

        return problem.status;
    }
    getProblemVenta(param) {
        let problems = this.getProblemsVenta(true);
        let problem = problems.find(p => p.key == param);

        return problem;
    }


}