export interface IXolidoCircuit {
    circuitId: number;
    circuitName: string;
    circuitCreationDate: Date;
    circuitFinished: boolean;
    circuitCancelled: boolean;
    senderIdUser: number;
    senderIdentifier: string;
    senderName: string;
    senderEmail: string;
    documentId: string;
    documentName: string;
    documentDestinationFolder: number;
    nodes: IXolidoCircuitNode[];
    created: Date;
    modified: Date;
}

export interface IXolidoCircuitNode {
    order: number;
    nodeId: number;
    finished: boolean;
    optional: boolean;
    cancelled: boolean;
    creationDate: Date;
    nodeRecipient: IXolidoCircuitNodeRecipient;
    sentEmailNotice: boolean;
}

export interface IXolidoCircuitNodeRecipient {
    internalNode?: IXolidoCircuitNodeInternal;
    externalNode?: IXolidoCircuitNodeExternal;
}

export interface IXolidoCircuitNodeInternal {
    name: string;
    email: string;
    idUser: number;
    userIdentifier: string;
    internalDocumentId: number;
    operationPerformed: string;
    operationRequested: string;
    operationIdentifier: string;
    operationPerformedIp: string;
    operationPerformedDate: Date;
}

export interface IXolidoCircuitNodeExternal {
    lang: string;
    email: string;
    identifier: string;
    communicationType: string;
    authenticationType: string;
    externalDocumentId: number;
    operationPerformed: string;
    operationRequested: string;
    operationIdentifier: string;
    operationPerformedIp: string;
    operationPerformedDate: Date;
}

// Clase implementadora opcional
export class XolidoCircuit implements IXolidoCircuit {
    circuitId: number;
    circuitName: string;
    circuitCreationDate: Date;
    circuitFinished: boolean;
    circuitCancelled: boolean;
    senderIdUser: number;
    senderIdentifier: string;
    senderName: string;
    senderEmail: string;
    documentId: string;
    documentName: string;
    documentDestinationFolder: number;
    nodes: IXolidoCircuitNode[];
    created: Date;
    modified: Date;

    static get modelName() {
        return 'XolidoCircuit';
    }

    isCompleted(): boolean {
        return this.circuitFinished && !this.circuitCancelled;
    }

    getParticipants(): string[] {
        return this.nodes.map(node =>
            node.nodeRecipient.internalNode?.email ||
            node.nodeRecipient.externalNode?.email
        ).filter(email => email !== undefined) as string[];
    }
}