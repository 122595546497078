<li ngbDropdown class="nav-item dropdown-notification mt-0 mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
    <i class="ficon" data-feather="bell"></i>
    <span *ngIf="notifications.length > 0" class="badge badge-pill badge-danger badge-up">{{ notifications.length }}</span>
  </a>
  <ul ngbDropdownMenu aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificaciones</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notifications.length }} sin leer
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex"
         (click)="handleNotificationClick(notification)"
         href="javascript:void(0)"
         *ngFor="let notification of notifications"
         [style.cursor]="notification.getUrl() ? 'pointer' : 'default'">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': notification.getIcon() === 'x',
                'bg-light-success': notification.getIcon() === 'check',
                'bg-light-warning': notification.getIcon() === 'alert-triangle',
                'bg-light-info': notification.getIcon() === 'bell'
              }"
            >
              <div class="avatar-content">
                <i class="avatar-icon feather" [data-feather]="notification.getIcon()"></i>
              </div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="notification.title"></p>
            <p *ngIf="notification.getNotificatedModelName() == 'mensaje'" class="media-heading">
              <small class="notification-text">{{(notification.getExtraInfo()?.length > 50 ? notification.getExtraInfo()?.slice(0,50) + '...' : notification.getExtraInfo())}}</small>
            </p>
            <small *ngIf="notification.getNotificatedModelName() != 'mensaje'" class="notification-text">{{ notification.body }}</small>

          </div>
        </div>
      </a>
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer" >
      <div class="d-flex justify-content-center" *ngIf="notifications.length > 0">
        <a class="btn btn-sm btn-secondary" (click)="markAllAsRead()">Marcar todo como leído</a>
      </div>
      <div class="d-flex justify-content-center" *ngIf="notifications.length === 0">
        <p class="text-center">No hay notificaciones</p>
      </div>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>