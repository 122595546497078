import { Type } from 'class-transformer';
import { XolidoCircuit } from './xolido-circuit.model';

export interface IXolidoDocument {
    idDocument: string;
    name?: string;
    summary?: string;
    metadata?: any;
    uri?: string;
    content?: string;
    ticketfile?: string;
    urlCallback?: string;
    svc?: string;
    creationDate?: Date;
    moreInfo?: any;
    notSign?: boolean;
    created?: Date;
    modified?: Date;
    foreign_key?: number;
    model?: string;
    grp?: string;
    xolido_circuits?: XolidoCircuit[];
}

export class XolidoDocument implements IXolidoDocument {
    public idDocument: string;
    public name?: string;
    public summary?: string;
    public metadata?: any;
    public uri?: string;
    public content?: string;
    public ticketfile?: string;
    public urlCallback?: string;
    public svc?: string;
    public creationDate?: Date;
    public moreInfo?: any;
    public notSign: boolean = false;
    public created?: Date;
    public modified?: Date;
    public foreign_key?: number;
    public model?: string;
    public grp?: string;
    @Type(() => XolidoCircuit)
    public xolido_circuits?: XolidoCircuit[];

    static get modelName() {
        return 'XolidoDocument';
    }

    // Método para obtener el estado del documento
    getEstado(): 'pendiente' | 'firmado' | 'rechazado' | 'error' | 'desconocido' {
        if (!this.moreInfo) {
            return 'pendiente';
        }

        try {
            const info = typeof this.moreInfo === 'string'
                ? JSON.parse(this.moreInfo)
                : this.moreInfo;

            // Aquí puedes implementar la lógica específica para determinar
            // el estado según la estructura de moreInfo
            if (info.estado === 'SIGNED') {
                return 'firmado';
            } else if (info.estado === 'REJECTED') {
                return 'rechazado';
            } else if (info.estado === 'ERROR') {
                return 'error';
            }

            return 'pendiente';
        } catch (e) {
            console.error('Error parsing moreInfo:', e);
            return 'desconocido';
        }
    }

    // Método para obtener la URL de visualización
    getViewerUrl(): string | null {
        return this.uri || null;
    }

    // Método para comprobar si el documento está disponible para firma
    isSignable(): boolean {
        return !this.notSign;
    }

    // Método para obtener la fecha de creación formateada
    getCreationDate(): Date | null {
        return this.creationDate ? new Date(this.creationDate) : null;
    }

    getSignaturesData(): any[] {
        return this.moreInfo?.signaturesData || [];
    }

    isFinished(): boolean {
        return this.moreInfo?.finished || false;
    }

    getSignatures(): any[] {
        return this.moreInfo?.signaturesData || [];
    }
}
